<!--
 * @Author: 小洋人
 * @Date: 2024-07-10 17:30:48
 * @LastEditors: 小洋人
 * @LastEditTime: 2024-07-11 10:10:10
 * @FilePath: \kangmisi-officialwebsite\src\views\newsCenterdetail.vue
 * @email: 1172457354@qq.com
 * @Description: 
-->
<template>
  <div class="page" id="page">
    <heade :active="5"></heade>
    <div style="height: 64px"></div>
    <div class="page-bg" :style="{ height: bannerHeight + 'px' }">
      <img
        src="../assets/images/newsCenter/bg.png"
        alt=""
        :style="{ height: bannerHeight + 'px' }"
      />
      <div class="page-text">
        <div class="china">资讯中心</div>
        <div class="english">Counseling center</div>
      </div>
    </div>
    <div class="content">
      <div class="tabbar">
        <div class="news" @click="back()">资讯中心</div>
        <div class="right"><i class="el-icon-arrow-right"></i></div>
        <div class="detai">查看详情</div>
      </div>
      <div class="details">
        <div class="title">{{ currentInfo.title }}</div>
        <div v-for="(item, index) in currentInfo.detailList" :key="index">
          <div
            v-if="item.type == 'img'"
            :style="{
              width: item.width,
              height: item.height,
              marginBottom: item.marginBottom,
            }"
          >
            <img
              :src="item.content"
              alt=""
              :style="{ width: item.width, height: item.height }"
            />
          </div>
          <div
            v-else
            :style="{
              color: item.color,
              fontWeight: item.fontWeight,
              marginBottom: item.marginBottom,
              fontSize: item.fontSize,
            }"
          >
            {{ item.content }}
          </div>
        </div>
      </div>
    </div>
    <!-- 首页 -->
    <template>
      <bottom></bottom>
    </template>
  </div>
</template>

<script>
import bottom from "./bottom.vue";
import heade from "./header.vue";

export default {
  components: { heade, bottom },
  data() {
    return {
      bannerHeight: 0,
      currentInfo: {},
      list: [
        {
          id: 1,
          title: "车辆监控与风险预警 让租赁 信贷 抵押车 汽车金融风控更简单",
          detailList: [
            {
              type: "text",
              content:
                "汽车因其便利性被广泛使用，汽车贷款行业随之蓬勃发展。汽车贷款企业需确保贷款车辆安全和客户信用风险得到有效控制，这就需要借助定位技术。车贷风控管理主要分为两方面：一是贷款前的车辆风险评估，二是贷款后对借款人还款能力和行为的监控。贷款前评估相对容易，而了解借款人行为则需要通过GPS定位系统来辅助，以降低车辆丢失风险。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details1/img1.png"),
              width: "912px",
              height: "744px",
              marginBottom: "24px",
            },
            {
              type: "text",
              content: "安装GPS北斗定位模块的原因包括：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "bold",
            },
            {
              type: "text",
              content: "防止借款人使用虚假身份逃贷。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content: "避免恶意骗贷导致贷款损失。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content: "减少因车辆二次抵押带来的还贷风险。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details1/img2.png"),
              width: "912px",
              height: "482px",
              marginBottom: "24px",
            },
            {
              type: "text",
              content: "GPS北斗定位模块的作用：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "700",
            },
            {
              type: "text",
              content: "提高贷款机构对车贷企业和借款人的信任度。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content: "实时收集并发送车辆驾驶数据至管理平台，实现秒级响应。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content: "通过设置电子围栏，预判风险，避免车辆二次抵押。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "定位模块还能监控车辆位置，一旦发现车辆频繁出现在二手车市场、维修点等敏感场所，可立即采取行动。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "针对汽车金融行业需求，康米斯从物联网及无线通信技术创新角度，以智能终端作为通讯入口采集车辆运行状态（位置、轨迹、告警等），依托康米斯大数据优势和技术能力得出可视化数据，帮助汽车金融公司打造标准化的贷后风控系统。通过智能监控管理和预警等手段，康米斯的方案可以降低客户运营成本，保障客户资产安全。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details1/img3.png"),
              width: "912px",
              height: "523px",
              marginBottom: "29px",
            },
            {
              type: "text",
              content:
                "车辆监控利用北斗/GPS/LBS/Wi-Fi等多种定位技术，不仅实现了对车辆的远程实时监控，还能精确记录车辆的行驶轨迹。GPS北斗定位模块作为数据收集终端，与云管理平台保持实时联系，将车辆的位置和轨迹信息同步上传。一旦监测到车辆位置出现异常，系统会立即发出告警，直至风险得到妥善处理。",
              fontSize: "16px",
              marginBottom: "32px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "车辆监控利用北斗/GPS/LBS/Wi-风险预警系统通过综合分析车辆的常驻位置和行为模式，结合多维度风险甄别技术，如监测车辆是否进入二押区域、长时间停留或异常出行等，显著提高了风险识别的准确性。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details1/img4.png"),
              width: "912px",
              height: "450px",
              marginBottom: "29px",
            },
            {
              type: "text",
              content:
                "车辆监控利用北斗/GPS/LBS/Wi-电子围栏功能能够识别车辆是否进入如二手车市场、抵押当铺、维修厂等敏感区域，并对在这些区域内停留时间过长的车辆发出警报，提醒管理人员采取相应措施。",
              fontSize: "16px",
              marginBottom: "32px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "行车路线规划功能根据车主提供的行车需求和位置信息，智能规划出合理高效的行车路线，提供导航服务。系统还致力于优化驾驶体验，通过收集油量、行驶路线、里程数等数据，进行状态诊断和车辆状态监测，帮助车主实现节能驾驶，提升行车效率。",
              fontSize: "16px",
              marginBottom: "32px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "此外，当车辆信息出现异常或被非法拆卸时，定位模块会立即向管理平台发送告警信息，确保管理人员能够迅速响应，不错过任何追车时机。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details1/img5.png"),
              width: "912px",
              height: "552px",
              marginBottom: "23px",
            },
            {
              type: "text",
              content:
                "综合来看，这些功能共同构建了一个全面的车辆信息监管和风险控制系统，为汽车贷款行业提供了强有力的技术支持和安全保障。",
              fontSize: "16px",
              marginBottom: "0px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
          ],
        },
        {
          id: 2,
          title: "提升效率，保障安全 康米斯打造简洁易用的车辆远程调度体系",
          detailList: [
             {
              type: "text",
              content:
                "如何高效管理车队和司机，降低成本并提高效率，是物流行业长期关注的问题。以下是几个提高车队管理效率的方法：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "img",
              content: require("../assets/images/newsCenter/details2/img1.png"),
              width: "912px",
              height: "608px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "数字化精细运营：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "700",
            },
             {
              type: "text",
              content:
                "利用车联网技术，安装GPS、视频监控和传感器，收集车辆数据。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "通过大数据和AI平台，实现货物运输全程可视化监管，有效减少资源浪费。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "数据的有效利用：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "700",
            },
            {
              type: "text",
              content:
                "深入分析车辆的安全、性能和油耗，确保运输效率和服务质量。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "通过数据分析，优化车辆与运输线路的匹配，改善驾驶行为，降低油耗。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "司机管理：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "700",
            },
            {
              type: "text",
              content:
                "与司机保持密切沟通，解决工作中的问题，并通过定期培训提升专业技能。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "根据安全、成本、效率和服务四个维度，对车队管理者和司机进行KPI考核。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "4G车载定位器作为一项关键工具，能够精确追踪目标车辆，从而确保物流和运输流程的顺畅。这种定位器能够提供车辆的即时信息，包括其当前位置、行驶路径、速度以及行进方向等关键数据。这些信息对于企业来说至关重要，有助于他们做出快速而准确的决策，提升物流效率和服务质量。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "康米斯专为货车管理提供基于4G车载定位器的物流车队管理解决方案，基于位置服务、视觉智能与通讯网络技术，通过硬件设备采集基础数据，为货运车辆管理提供实时定位追踪、安全视频监控 、行车轨迹回溯 、司机驾驶行为汇总、多重告警通知等信息，帮助车队管理降本增效的同时，提高货运安全性。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
               {
              type: "img",
              content: require("../assets/images/newsCenter/details2/img2.png"),
              width: "912px",
              height: "503px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "以下是康米斯物联物流车队管理解决方案的主要优势： 、行车轨迹回溯 、司机驾驶行为汇总、多重告警通知等信息，帮助车队管理降本增效的同时，提高货运安全性。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "1. 多车管理，同时监控：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "通过先进的车队管理系统，一个用户名下可以同时监控多个车辆的实时位置。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "不论车辆距离多远或数量多少，管理者都能随时随地通过电脑或移动设备了解车辆的动态。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "系统提供直观的界面，让管理者能够轻松查看每辆车的状态，包括位置、速度和行驶方向。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "img",
              content: require("../assets/images/newsCenter/details2/img3.png"),
              width: "912px",
              height: "462px",
              marginBottom: "24px",
            },
            {
              type: "text",
              content:
                "2. 轨迹回放，一目了然：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "车辆的行驶路线、经过地点、停留时间、车速、方向和里程等信息都会被自动保存在云平台上。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "管理者只需打开手机APP，即可随时查看车辆的历史轨迹，帮助分析行车效率和优化路线规划。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "轨迹回放功能使得管理者能够清晰地了解车辆的行驶情况，便于进行事后分析和问题排查。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "3. GPS+北斗双星定位：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "系统采用GPS和北斗双星定位技术，提供全天候、高精度的定位服务。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "无论是在城市高楼林立的环境中，还是在偏远地区，都能保证定位的准确性和通信的实时性。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "双星定位技术的应用，增强了系统的可靠性，确保车辆位置信息的连续性和稳定性。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "4. 支持多种报警功能：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "系统支持超速、位移、断电、围栏等多种报警功能，能够及时提醒管理者注意车辆的异常状态。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "例如，当车辆超速或离开设定的电子围栏时，系统会自动发出警报，帮助管理者迅速采取相应措施。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "这些报警功能大大提高了车辆管理的主动性和预防性，降低了潜在风险。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
                {
              type: "img",
              content: require("../assets/images/newsCenter/details2/img4.png"),
              width: "912px",
              height: "590px",
              marginBottom: "24px",
            },
            {
              type: "text",
              content:
                "5. 电子围栏功能：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "管理者可以在地图上设置警戒区域，即电子围栏，对车辆的行驶范围进行限制。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "一旦车辆离开或进入设定的警戒范围，系统会立即触发进出围栏的警告。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "这项功能有助于规范司机的驾驶行为，防止未经授权的车辆使用，确保车辆按照预定路线行驶，杜绝私自改变路线或拉私活的情况。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "img",
              content: require("../assets/images/newsCenter/details2/img5.png"),
              width: "912px",
              height: "478px",
              marginBottom: "24px",
            },
            {
              type: "text",
              content:
                "康米斯解决方案不仅优化了车辆的实时监控和调度，还极大地提高了物流运输的效率和安全性。随着康米斯物联技术的不断进步，它正在帮助企业实现更加智能化、自动化的车队管理，这不仅为企业带来了成本效益，也为整个物流行业的可持续发展贡献了力量。随着康米斯物联技术的进一步发展，我们期待它将带来更多创新的解决方案。",
              fontSize: "16px",
              marginBottom: "0px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
          ],
        },
         {
          id: 3,
          title: "从传统工地到智慧工地 车辆/人员定位终端助力构建安全高效的管理",
          detailList: [
             {
              type: "text",
              content:
                "在建筑行业中，施工安全是至关重要的基石，它不仅保障了施工企业能够顺利进行工作，而且直接影响到生产效率的提升。随着信息技术、移动技术、智能穿戴设备和工具的快速发展，智慧工地的概念应运而生，为施工安全管理带来了革命性的变革。同时，中国建筑业协会发布的《智慧工地技术标准》公告，为智慧工地建设提供了规范化、智能化和标准化的重要指导。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "img",
              content: require("../assets/images/newsCenter/details3/img1.png"),
              width: "912px",
              height: "512px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "在这一背景下，康米斯作为一家专注于室内外融合定位服务的提供商，严格遵循这些标准，利用自主研发的融合定位技术，为建筑施工行业提供了一套全面的人员车辆定位系统解决方案。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "img",
              content: require("../assets/images/newsCenter/details3/img2.png"),
              width: "912px",
              height: "503px",
              marginBottom: "24px",
            },
              {
              type: "text",
              content:
                "康米斯推出的智慧工地人员车辆定位系统解决方案，使用物联网智能终端作为通讯入口，收集外勤车辆和员工的状态信息，包括位置、轨迹、告警信息和监控视频等。该方案以位置服务和通讯网络技术为核心，通过硬件设备和云端后法的协同工作，为工地管理提供了全面的服务：",
              fontSize: "16px",
              marginBottom: "32px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "康米斯的智慧工地人员车辆定位系统解决方案在多个方面展现出显著的优势：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "700",
            },
            {
              type: "text",
              content:
                "1. 实时监控与响应：通过实时定位追踪和安全视频监控，管理者可以即时了解车辆和人员的位置与状态，对任何异常情况做出快速响应。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
               {
              type: "img",
              content: require("../assets/images/newsCenter/details3/img3.png"),
              width: "912px",
              height: "565px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "2. 数据驱动的决策支持：利用大数据算法分析行车轨迹和司机驾驶行为，为管理者提供数据支持，帮助他们做出更加精准和科学的管理决策。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "3. 提高生产效率：通过智能调度和协同作业，优化车辆和人员资源分配，减少等待和空闲时间，从而提高施工效率和生产力。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "4. 安全管理：多重告警通知系统能够对驾驶行为监测，当检测到三急一碰行为自动报警发出预警，减少事故发生的可能性，保障司机的安全。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "5. 透明的车队管理：系统收集的车辆信息使得车队管理更加透明，易于追踪和审计，提高了管理的公正性和效率。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "6. 智能化管理：IoT管理解决方案使得外勤管理更加智能化，通过电子工牌和智能终端，外勤员工和司机可以更便捷地完成任务记录和紧急响应。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "7. 降低运营成本：无纸化考核和高效排班减少了管理过程中的资源消耗，降低了运营成本。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "8. 提升员工满意度：智能打卡和一键求救功能提高了员工的工作便利性和安全感，从而提升了员工的满意度和忠诚度。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
               {
              type: "img",
              content: require("../assets/images/newsCenter/details3/img4.png"),
              width: "912px",
              height: "517px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "9. 环境适应性强：系统设计考虑到了不同施工环境的特殊需求，无论是城市高层建筑还是偏远地区的施工现场，都能提供稳定的服务。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "10. 定制化服务：针对该方案，康米斯可提供定制化服务，根据不同施工企业的具体需求，调整和优化系统功能，确保解决方案的最大适用性。",
              fontSize: "16px",
              marginBottom: "32px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "综合来看，康米斯的智慧工地人员车辆定位系统解决方案不仅提升了施工安全管理的水平，也为施工企业带来了运营效率和经济效益的显著提升。通过这些优势，该解决方案为建筑施工行业提供了一个高效、安全、智能的管理平台，推动了智慧工地的发展和施工安全管理的进步。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
          ],
        },
         {
          id: 4,
          title: "智慧冷链解决方案 实时温湿度监测 加强货物监控与安全",
          detailList: [
             {
              type: "text",
              content:
                "随着人们对生鲜冷链需求的日益增长，冷链物流行业正迎来以物联网技术为主导的发展新阶段。我国冷链物流业正处于快速发展期，向网络化、标准化、规模化、集团化方向迅速迈进，展现出巨大的市场潜力。然而，现有的冷链车监控系统往往无法提供全面的监控，冷链运输的温度控制、操作规范等方面缺少统一标准，各环节信息资源难以有效整合，一旦出现问题，难以进行有效追溯。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "img",
              content: require("../assets/images/newsCenter/details4/img1.png"),
              width: "912px",
              height: "543px",
              marginBottom: "24px",
            },
              {
              type: "text",
              content:
                "针对这些问题，康米斯推出了冷链定位器L345 4G卡片式定位器，为智慧冷链物流提供了解决方案。冷链车常用于运输需要严格保鲜的食品、水果、药品等，对货厢内温度的控制要求极高。康米斯提出的智能管理方案，集成了车辆位置监控、车厢温湿度监控、驾驶员行为分析等多项功能，形成了一个全面的物联网解决方案。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "img",
              content: require("../assets/images/newsCenter/details4/img2.png"),
              width: "912px",
              height: "472px",
              marginBottom: "24px",
            },
            
             {
              type: "text",
              content:
                "该智慧冷链车方案能够对货物、车辆2个关键主体进行细致监控：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "车辆监控：提供定位信息，为车队管理提供数据支持，确保货物运输安全。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
                {
              type: "text",
              content:
                "货物监控：利用GPS定位器监测车厢内的温度和湿度，确保货物在整个运输过程中保持新鲜。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
               {
              type: "text",
              content:
                "通过这种集成化的智能监控系统，冷链物流企业能够实现更高效、更智能的车辆管理，提升服务质量，满足市场对高效冷链运输的迫切需求。L345作为一款高性能的手持设备，具备以下功能，能够满足不同场景下的使用需求：",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "img",
              content: require("../assets/images/newsCenter/details4/img3.png"),
              width: "912px",
              height: "912px",
              marginBottom: "24px",
            },
               {
              type: "text",
              content:
                "1. 四重定位系统：L345采用GPS+BDS/Wi-Fi/LBS的多重定位技术，确保设备能够快速准确地确定位置，无论是在城市高楼林立的环境中，还是在偏远的郊区，都能提供稳定的定位服务。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "2. 三种工作模式：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "- 实时定位模式：提供连续的位置更新，适合对位置信息要求高的应用场景。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "- 智能模式：根据实际使用情况自动调整定位频率，平衡定位精度与电量消耗。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "- 超长待机模式：优化电量使用，延长设备的使用时间，适合长时间不需要频繁定位的场合。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "3. 多重报警信息：设备具备多种报警功能可帮助管理人员及时发现并应对各种情况，减少企业的潜在损失。包括：",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "- 超速报警：当设备超过预设速度时触发报警。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
               {
              type: "text",
              content:
                "- 围栏报警：设备进出预设的电子围栏区域时发出警报。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "- 低电报警：电量低于设定值时提醒用户。",
              fontSize: "16px",
              marginBottom: "16px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
            {
              type: "text",
              content:
                "- 拆箱报警：设备被拆箱时触发报警，适用于防止设备被盗。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "4. 温湿度监测：L345能够实时监控环境的温湿度变化，一旦超过预设的安全值，将自动发出报警，对于需要精细温控的行业（如医药、食品冷链等）尤为重要。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "text",
              content:
                "5. 温度传感器的灵活性：用户可以根据冷链车的具体需求，调整温度传感器的数量和安装位置，实现更精准的温控管理。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
             {
              type: "text",
              content:
                "L345手持设备的这些功能，使其成为一个多用途、高效率的工具，适用于物流跟踪、资产管理、环境监测等多种应用场景，为企业提供了一个全面、可靠的管理解决方案。",
              fontSize: "16px",
              marginBottom: "24px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
              {
              type: "img",
              content: require("../assets/images/newsCenter/details4/img4.png"),
              width: "912px",
              height: "290px",
              marginBottom: "24px",
            },
             {
              type: "text",
              content:
                "通过康米斯智慧冷链解决方案，冷链物流企业不仅能够提高运输效率，保障货物安全，还能够在问题发生时迅速定位并采取措施，最大程度地减少损失。同时，这些数据也为企业提供了一个宝贵的信息资源，有助于分析运输过程中的效率瓶颈，优化运输路线和调度策略，进一步提升服务质量和市场竞争力。",
              fontSize: "16px",
              marginBottom: "0px",
              color: "#2C2C2C",
              fontWeight: "300",
            },
          ],
        },
      ],
    };
  },
  mounted() {
    window.scrollTo(0, 0);
    this.imgLoag();
    window.addEventListener(
      "resize",
      () => {
        this.imgLoag();
      },
      false
    );
    this.currentInfo = this.list.find(
      (item) => item.id ==  this.$route.query.id
    );
    console.log(this.currentInfo, "this.currentInfo");
  },
  methods: {
    back(){
        this.$router.go(-1)
    },
    imgLoag() {
      this.$nextTick(() => {
        const page = document.getElementById("page");
        const pageWidth = page.offsetWidth;
        this.bannerHeight = (pageWidth * 560) / 1920;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.page-bg {
  width: 100vw;
  position: relative;
  img {
    width: 100vw;
  }
}
.page-text {
  position: absolute;
  top: 42%;
  left: 19.1%;
  color: #fefefe;
  // text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.16);
  .china {
    font-weight: 300;
    font-size: 40px;
  }
  .english {
    font-weight: 300;
    font-size: 24px;
    margin-top: 3px;
  }
}
.content {
  min-width: 1200px;
  width: 1200px;
  margin: 0 auto;
  margin-top: 20px;
  background-color: #fff;
  .tabbar {
    display: flex;
    padding: 24px;
    border-bottom: 1px solid #dbdbdb;
    .news {
      margin-left: 20px;
      width: 64px;
      height: 21px;
      font-size: 16px;
      color: #2c2c2c;
      cursor: pointer;
    }
    .right {
      color: #2c2c2c;
      margin: 0 5px;
    }
    .detai {
      font-weight: bold;
      font-size: 16px;
      color: #0f3391;
    }
  }
}
.details {
  width: 912px;
  margin: 0 auto;
  margin-top: 56px;
  margin-bottom: 200px;
  //   background-color: pink;
  .title {
    font-weight: 400;
    font-size: 28px;
    color: #2c2c2c;
    text-align: center;
    margin-bottom: 32px;
  }
}
</style>


