var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page",attrs:{"id":"page"}},[_c('heade',{attrs:{"active":5}}),_c('div',{staticStyle:{"height":"64px"}}),_c('div',{staticClass:"page-bg",style:({ height: _vm.bannerHeight + 'px' })},[_c('img',{style:({ height: _vm.bannerHeight + 'px' }),attrs:{"src":require("../assets/images/newsCenter/bg.png"),"alt":""}}),_vm._m(0)]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"tabbar"},[_c('div',{staticClass:"news",on:{"click":function($event){return _vm.back()}}},[_vm._v("资讯中心")]),_vm._m(1),_c('div',{staticClass:"detai"},[_vm._v("查看详情")])]),_c('div',{staticClass:"details"},[_c('div',{staticClass:"title"},[_vm._v(_vm._s(_vm.currentInfo.title))]),_vm._l((_vm.currentInfo.detailList),function(item,index){return _c('div',{key:index},[(item.type == 'img')?_c('div',{style:({
            width: item.width,
            height: item.height,
            marginBottom: item.marginBottom,
          })},[_c('img',{style:({ width: item.width, height: item.height }),attrs:{"src":item.content,"alt":""}})]):_c('div',{style:({
            color: item.color,
            fontWeight: item.fontWeight,
            marginBottom: item.marginBottom,
            fontSize: item.fontSize,
          })},[_vm._v(" "+_vm._s(item.content)+" ")])])})],2)]),[_c('bottom')]],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-text"},[_c('div',{staticClass:"china"},[_vm._v("资讯中心")]),_c('div',{staticClass:"english"},[_vm._v("Counseling center")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right"},[_c('i',{staticClass:"el-icon-arrow-right"})])
}]

export { render, staticRenderFns }